<template>
  <div class="outside">
    <!-- <banner /> -->
    <div class="top-banner">
      <img src="../../assets/img/home/banner2.jpg" alt="" srcset="">
    </div>
    <div class="contact_us">
      <div class="edition_heart">
        <div class="title">联系我们</div>
        <div class="titleEng">contact us</div>
        <div class="Company">
          山东文保文物修复技术有限公司
        </div>
        <div class="CompanyEn">
          Shandong Wenbao Cultural Heritage Conservation Co. , Ltd
        </div>
        <div class="contact-info">
          <div class="contact address">地址：山东省济南市高新区港兴三路1003号</div>
          <div class="contact tel">手机：15863162867/18615551013</div>
        </div>
        <div class="map">
          <GaoDeMap />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner from '/src/components/banner.vue'
import GaoDeMap from './GaoDeMap.vue'

export default {
  name: 'contactUs',
  components: { banner, GaoDeMap },
  data() {
    return {}
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
.top-banner {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    min-width: 1920px;
    height: 400px !important;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.outside {
  padding-top: 120px;
  background-color: #F9F7F3;

  .contact_us {
    width: 100%;
    padding-bottom: 120px;

    // background-position: top right;
    .title {
      padding-top: 80px;
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
      color: #333;
    }

    .titleEng {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .map {
      width: 100%;
      height: 500px;
      margin-top: 80px;
    }

    .Company {
      margin-top: 54px;
      font-size: 26px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 600;
      text-align: left;
      color: #666;
    }

    .CompanyEn {
      opacity: 0.4;
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Regular;
      text-align: left;
      color: #666;
      line-height: 30px;
      margin-bottom: 54px;
    }

    .contact {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #333;
      line-height: 40px;
      position: relative;
      padding-left: 40px;
    }
  }

  .edition_heart {
    width: 62.5%;
    margin: 0px auto;
    position: relative;
  }

  .contact-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .address::before {
    content: "";
    width: 32px;
    height: 32px;
    background: url(../../assets/img/contactUs/address.png) center center no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -16px;
  }

  .tel::before {
    content: "";
    width: 32px;
    height: 32px;
    background: url(../../assets/img/contactUs/tel.png) center center no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -16px;
  }
}

@media screen and (max-width: 768px) {
  .outside {
    padding-top: 60px;
  }

  .top-banner {
    display: none;
  }
}
</style>
