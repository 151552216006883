<template>
  <div class="app-container">
    <div id="container" style="position: relative; width: 100%; height: 500px"></div>
  </div>
</template>

<script>
/* eslint-disable */
import MapLoader from '@/utils/loadGMap.js'
export default {
  name: 'geoMap',
  props: {
    lng: {
      type: [String, Number],
      default: '117.221933',
    },
    lat: {
      type: [String, Number],
      default: '36.656994',
    },
    address: {
      type: String,
      default: '',
    },
    status: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      AMap: '',
      map: '',
      form: {
        longitude: '',
        latitude: '',
        MapOpen: true,
        open: true,
        address: '', // 详细地址
      },
    }
  },
  async mounted() {
    this.form.longitude = this.lng
    this.form.latitude = this.lat
    const key = 'adcec2886d44b1e1e67ee0126013977c'
    this.AMap = await MapLoader(key)
    this.initAMap()
  },
  methods: {
    async initAMap() {
      const that = this
      // MapLoader(key).then((AMap) => {
      let LngLat = new AMap.LngLat(that.form.longitude, that.form.latitude)
      that.map = new AMap.Map('container', {
        zoom: 12, // 级别
        mapStyle: 'amap://styles/normal', // 设置颜色底层
        center: LngLat, // 中心点坐标
        viewMode: '2D', // 使用3D视图
      })
      let marker = new AMap.Marker({
        icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
        position: LngLat,
        offset: new AMap.Pixel(-13, -30),
      })
      marker.setMap(that.map)
      // 同时引入工具条插件，比例尺插件和鹰眼插件
      AMap.plugin(
        ['AMap.ToolBar', 'AMap.Scale', 'AMap.Geolocation', 'AMap.AutoComplete', 'AMap.Geocoder'],
        function() {
          // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
          that.map.addControl(
            new AMap.ToolBar({
              position: {
                top: '90px',
                right: '40px',
              },
            })
          )
          // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
          that.map.addControl(new AMap.Scale())
          // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
          that.map.addControl(new AMap.Geolocation())
          new AMap.Autocomplete()
          new AMap.Geocoder()
        }
      )

      marker.setLabel({
        direction: 'right',
        offset: new AMap.Pixel(5, 0), //设置文本标注偏移量
        content: '山东文保文物修复有限公司', //设置文本标注内容
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.autoAddressClass {
  li {
    i.el-icon-search {
      margin-top: 11px;
    }
    .mgr10 {
      margin-right: 10px;
    }
    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 10px;
    }
    .address {
      line-height: 1;
      font-size: 12px;
      color: #b4b4b4;
      margin-bottom: 5px;
    }
  }
}
</style>
